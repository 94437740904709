import React, { useState, useRef, useEffect } from 'react';
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Paper, TextField, Zoom, TextareaAutosize, ListItemButton, Popover, InputAdornment } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddTemplates from 'pages/organization/group-template/chatTemplates/add-update-template'
import { Button } from 'devextreme-react/data-grid';
import MDBox from 'components/MDBox';
import ImageIcon from '@mui/icons-material/Image';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import DescriptionIcon from '@mui/icons-material/Description';
import { ScrollView, TextArea } from 'devextreme-react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import SendIcon from '@mui/icons-material/Send';
import { StylesProvider, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MDTypography from "components/MDTypography";
import PeopleIcon from '@mui/icons-material/People';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import VideoViewer from './videoViewer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { sendMessage } from 'Apis/config/Url'
import { uploadFile } from 'Apis/config/Url'
import Caller from 'Apis/config/Caller';
import { PUT, GET, POST } from 'Apis/config/RequestType';
import { BeatLoader, ClipLoader, PuffLoader } from 'react-spinners';
import { useSnackbar } from 'notistack';
import { getWhatsAppChatHistory } from 'Apis/Auth/auth';
import { getCommunicationGroup } from 'Apis/Auth/auth';
import { getMobile } from 'Apis/config/Url';
import { check_whatsapp_group_name } from 'Apis/config/Url';
import { getTemplateFromTable } from 'Apis/config/Url';
import { getMetaTemplate } from 'Apis/config/Url';
import { getWhatsAppUserChatHistory } from 'Apis/Auth/auth';
import { setIsRead } from 'Apis/config/Url';
import EmojiPicker, { Emoji } from 'emoji-picker-react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { chat_history } from 'Apis/config/Url';
import { BASE_URL } from 'Apis/config/Url';

import { downloadMedia } from 'Apis/config/Url';
import { deleteMedia } from 'Apis/config/Url';
import DownloadIcon from '@mui/icons-material/Download';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const WHATSAPP_TOKEN = "Bearer EAAE1j6ZAnloQBO6bDpXBan2cSGCxCaHVqzNdcALfboDeA4RoWc5l0hT0DQEAtS5RhdIK4TV46VPlENZBZAQ6fzgd6KBGEZCpy0ZA9A3dUXcHahs8XXt0oomEZCN58h35uVTiE6yy3LBv8CJuYF5ffpYKwA7RhDUdZB8HrKrZAMw7IJ0jwy7eGkqmKfnI";   //main token


const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    color: "#7b809a ",
                },
            },
        },
    },
});


const useStyles = makeStyles({
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    iconClicked: {
        backgroundColor: '#0a4490 !important',
        color: 'white !important'
    },
    scrollableTextArea: {
        width: '100%',
        overflowY: 'auto',
        border: 'none', // Remove the border
        resize: 'none', // Disable text area resizing
    }
});

const ChatPage = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [chatMessages, setChatMessages] = useState([]); // State to store chat messages
    const [newMessage, setNewMessage] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageId, setImageId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videoId, setVideoId] = useState("");
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [documentId, setDocumentId] = useState(null);
    const [documentName, setDocumentName] = useState("");
    const [imageName, setImageName] = useState(''); // State to store the image name
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [isSelectedVideo, setIsSelectedVideo] = useState(null);
    const [isSelectedDocument, setIsSelectedDocument] = useState(null);
    const [isImagePopupOpen, setImagePopupOpen] = useState(false);
    const [whatsAppContactList, setWhatsAppContactList] = useState([]);
    const [whatsAppGroupsList, setWhatsAppGroupsList] = useState([]);
    const [whatsAppContactSearchList, setWhatsAppContactSearchList] = useState([]);
    const [whatsAppGroupsSearchList, setWhatsAppGroupsSearchList] = useState([]);
    const [userSearchInput, setUserSearchInput] = useState('');
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isDropdownDialogOpen, setDropdownDialogOpen] = useState(false);
    const [isImageDialogOpen, setImageDialogOpen] = useState(false);
    const [isVideoDialogOpen, setVideoDialogOpen] = useState(false);
    const [isDocumentDialogOpen, setDocumentDialogOpen] = useState(false);
    const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
    const [isChatIconClicked, setIsChatIconClicked] = useState(true);
    const [isPeopleIconClicked, setIsPeopleIconClicked] = useState(false);
    const [openVideoClickDialog, setOpenVideoClickDialog] = useState(false);
    const [mobileNumberArray, setMobileNumberArray] = useState([]);
    const chatHeightRef = useRef();
    const scrollViewRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [metaImageUrl, setMetaImageUrl] = useState([]);
    const [media_url_path, setMediaUrlPath] = useState("");
    const isMounted = useRef(true);
    const intervalId = useRef(null);
    const [variable_value, setVariableValue] = useState([]);
    const [downloadBtnLoader, setDownloadBtnLoader] = useState(false);
    const [loadingIndex, setLoadingIndex] = useState(null);
    const [tempMediaId, setTempMediaId] = useState("");
    const [isSendingMessageDisabled, setIsSendingMessageDisabled] = useState(false);

    useEffect(() => {
        if (chatHeightRef.current) {
            chatHeightRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [chatMessages, newMessage]);

    const imageFunction = async (chatsArray) => {
        let templateIdArray = chatsArray.filter(obj => obj.chat_id !== '' && obj.chat_id != null && !obj.chat_id.startsWith('wamid')).map(obj => obj.chat_id)
        let images = await Promise.all(
            templateIdArray.map(async (template_id) => {
                try {
                    let res = await Caller(getTemplateFromTable + `?take=25&requireTotalCount=true&id=${template_id}`, "", GET, false, true);
                    let template_name = res.data?.data[0]?.template_name;
                    let metaRes = await Caller(getMetaTemplate, "", GET, false, true);
                    let filtered = metaRes.data?.data?.filter(metaItem => metaItem.name.includes(template_name));
                    let imgUrl = filtered[0]?.components[0]?.example?.header_handle[0] || "";

                    return { [template_id]: imgUrl };
                } catch (error) {
                    console.error("Error fetching data:", error);
                    return ""; // return an empty string if there's an error
                }
            })
        );
        setMetaImageUrl(images);
    }

    useEffect(() => {
        (async () => {
            await getWhatsAppChatHistory('?take=25&requireTotalCount=true&isLoadingAll=true').then((res) => {
                if (res.success == true) {
                    setWhatsAppContactList(res.data.data.filter((tag, index, array) => array.findIndex(t => t.mobile == tag.mobile) == index));
                }
            })

            await getCommunicationGroup('?take=25&requireTotalCount=true&isLoadingAll=true').then((res) => {
                if (res.success == true) {
                    setWhatsAppGroupsList(res.data.data);
                }
            })

            if (props.currentChat.is_group) {
                await getWhatsAppUserChatHistory('?group_id=' + props.currentChat.id).then((res) => {
                    if (res.success == true) {
                        let chatsArray = res.data.data.filter((elm) => { return elm.whatsapp_message != null || (elm.message_type == "image" || elm.message_type == "video" || elm.message_type == "document" || elm.message_type == "broadcast" || elm.message_type == "template") }).sort((a, b) => (new Date(a.updated_at).toLocaleString('en-IN')) - (new Date(b.updated_at))).map(elm => ({ text: elm.whatsapp_message, message_type: elm.message_type, chat_id: elm.chat_id, document: elm.message_type == "document" ? elm.media_url_path : "", video: elm.message_type == "video" ? elm.media_url_path : "", image: elm.message_type == "image" ? elm.media_url_path : "", nature: elm.message_nature, time: (new Date(elm.updated_at).toLocaleString('en-IN')) }));
                        setChatMessages(chatsArray);
                        imageFunction(chatsArray);
                    }
                })
            }
            else {
                await getWhatsAppUserChatHistory('?mobile=' + props.currentChat.mobile).then(async (res) => {
                    if (res.success == true) {
                        let chatsArray = res.data.data.filter((elm) => { return elm.whatsapp_message != null || (elm.message_type == "image" || elm.message_type == "video" || elm.message_type == "document" || elm.message_type == "broadcast" || elm.message_type == "template") }).sort((a, b) => (new Date(a.updated_at).toLocaleString('en-IN')) - (new Date(b.updated_at))).map(elm => ({
                            text: elm.whatsapp_message, message_type: elm.message_type, chat_id: elm.chat_id, document: elm.message_type == "document" ? elm.media_url_path : "", video: elm.message_type == "video" ? elm.media_url_path : "", image: elm.message_type == "image" ? elm.media_url_path : "", nature: elm.message_nature, media_id: elm.message_type == "image" || elm.message_type == "document" || elm.message_type == "video" ? elm.media_id : "", time: (new Date(elm.updated_at).toLocaleString('en-IN'))
                        }));
                        setChatMessages(chatsArray);
                        imageFunction(chatsArray);
                        const formData = new FormData();
                        formData.append('chat_id', props.currentChat.id);
                        formData.append('mobile', props.currentChat.mobile);
                        await Caller(setIsRead, formData, POST, false, true);
                    }
                })
            }

            setIsLoading(false);

        })()
    }, [props])

    useEffect(() => {
        const fetchData = async () => {
            intervalId.current = setInterval(async () => {
                await getWhatsAppChatHistory('?take=25&requireTotalCount=true&isLoadingAll=true').then((res) => {
                    if (res.success == true) {
                        setWhatsAppContactList(res.data.data.filter((tag, index, array) => array.findIndex(t => t.mobile == tag.mobile) == index));
                    }
                })

                await getCommunicationGroup('?take=25&requireTotalCount=true&isLoadingAll=true').then((res) => {
                    if (res.success == true) {
                        setWhatsAppGroupsList(res.data.data);
                    }
                })

                if (props.currentChat.is_group) {
                    await getWhatsAppUserChatHistory('?group_id=' + props.currentChat.id).then((res) => {
                        if (res.success == true) {
                            let chatsArray = res.data.data.filter((elm) => { return elm.whatsapp_message != null || (elm.message_type == "image" || elm.message_type == "video" || elm.message_type == "document" || elm.message_type == "broadcast" || elm.message_type == "template") }).sort((a, b) => (new Date(a.updated_at).toLocaleString('en-IN')) - (new Date(b.updated_at).toLocaleString('en-IN'))).map(elm => ({ text: elm.whatsapp_message, message_type: elm.message_type, chat_id: elm.chat_id, document: elm.message_type == "document" ? elm.media_url_path : "", video: elm.message_type == "video" ? elm.media_url_path : "", image: elm.message_type == "image" ? elm.media_url_path : "", nature: elm.message_nature, chat_id: elm.chat_id, time: (new Date(elm.updated_at).toLocaleString('en-IN')) }));
                            setChatMessages(chatsArray);
                        }
                    })
                }
                else {
                    await getWhatsAppUserChatHistory('?mobile=' + props.currentChat.mobile + '&latest_entry=true').then(async (res) => {
                        if (res.success == true) {
                            if (res.data.data.length != 0) {
                                let newChat = res.data.data.filter((elm) => { return elm.whatsapp_message != null || (elm.message_type == "image" || elm.message_type == "video" || elm.message_type == "document" || elm.message_type == "broadcast" || elm.message_type == "template") }).sort((a, b) => (new Date(a.updated_at).toLocaleString('en-IN')) - (new Date(b.updated_at).toLocaleString('en-IN'))).map(elm => ({ text: elm.whatsapp_message, message_type: elm.message_type, chat_id: elm.chat_id, document: elm.message_type == "document" ? elm.media_url_path : "", video: elm.message_type == "video" ? elm.media_url_path : "", image: elm.message_type == "image" ? elm.media_url_path : "", nature: elm.message_nature, chat_id: elm.chat_id, media_id: elm.message_type == "image" || elm.message_type == "document" || elm.message_type == "video" ? elm.media_id : "", time: (new Date(elm.updated_at).toLocaleString('en-IN')) }));
                                setChatMessages(prevState => [...prevState, newChat[0]])
                            }

                        }
                    })
                }
                if (!isMounted.current) {
                    clearInterval(intervalId.current);
                    return;
                }
            }, 10000);
        };

        fetchData();

        return () => {
            isMounted.current = false; // Set the flag to false when the component unmounts
            clearInterval(intervalId.current);
        }; // Cleanup to set the flag to false when the component unmounts
    }, [props]);

    function convertUnicodeToBinary(data) {
        return data.replace(/\\u[0-9a-fA-F]{4}/g, function (match) {
            return String.fromCharCode(parseInt(match.substr(2), 16));
        });
    }

    const mapFile = async (filePath) => {
        let url = "";
        if (BASE_URL.includes('127.0.0.1')) {
            url = filePath ? filePath : "";
        } else {
            let newPath = filePath ? filePath.replace('../../', '') : "";
            url = BASE_URL + newPath;
        }

        return url;
    };


    const handleEmojiClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseEmojiPicker = () => {
        setAnchorEl(null);
    };

    const handleVideoClick = (videoUrl) => {
        setSelectedVideo(videoUrl);
        setOpenVideoClickDialog(true);
    };

    const closeVideoClick = () => {
        setOpenVideoClickDialog(false);
        setSelectedVideo('');
    };

    const openImageDialog = async (imageUrl) => {
        setSelectedImage(imageUrl); setImageDialogOpen(true);

    };

    const closeImageDialog = () => {
        setSelectedImage(null);
        setImageDialogOpen(false);
    };

    const handleChatIconClick = () => {
        setIsChatIconClicked(true);
        setChatMessages([]);
        setIsPeopleIconClicked(false);
        setMobileNumberArray([]);
    };

    const handleGroupIconClick = () => {
        setIsChatIconClicked(false);
        setChatMessages([]);
        setIsPeopleIconClicked(true);
    };


    // Function to handle clicking on the ImageIcon
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setDialogOpen(false);
        }
    }

    const openVideoDialog = () => {
        setVideoDialogOpen(true);
    };

    const closeVideoDialog = (event, reason) => {
        if (reason !== 'backdropClick') {
            setDropdownDialogOpen(false);
            setVideoDialogOpen(false);
        }

    };

    const handleVideoChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoader(true);
            setSelectedVideo(URL.createObjectURL(file));
            openVideoDialog(); // Open the video dialog
            setIsSelectedVideo(true);
            setDropdownDialogOpen(false);

            const formData = new FormData();
            formData.append('file', file);

            // upload video to get id
            await Caller(uploadFile, formData, POST, false, true).then(async (resData) => {
                setMediaUrlPath(resData.data.media_url_path);
                setVideoId(resData.data.data.id);
                setLoader(false);
            });

        }
    };

    // Function to handle file input change (image selection)
    const handleImageChange = async (e) => {
        setDropdownDialogOpen(false);
        const file = e.target.files[0];
        if (file) {
            setLoader(true);
            setSelectedImage(URL.createObjectURL(file));
            setIsImageSelected(true); // Set the image selection state
            setImagePopupOpen(true);

            const formData = new FormData();
            formData.append('file', file);

            // upload file to get id
            await Caller(uploadFile, formData, POST, false, true).then(async (resData) => {
                setMediaUrlPath(resData.data.media_url_path || "");
                setImageId(resData.data.data.id || "");
                setLoader(false);
            });


        }
    };

    const handleDocumentChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoader(true);
            setSelectedDocumentUrl(URL.createObjectURL(file));
            setSelectedDocument(file);
            openDocumentDialog(); // Open the document dialog
            setIsSelectedDocument(true);
            setDropdownDialogOpen(false);
            setDocumentName(file.name);

            const formData = new FormData();
            formData.append('file', file);

            // upload document to get id
            await Caller(uploadFile, formData, POST, false, true).then(async (resData) => {
                setMediaUrlPath(resData.data.media_url_path);
                setDocumentId(resData.data.data.id);
                setLoader(false);
            });

        }
    };

    const openDocumentDialog = () => {
        setDocumentDialogOpen(true);
    };

    const closeDocumentDialog = (event, reason) => {
        if (reason !== 'backdropClick') {
            setDropdownDialogOpen(false);
            setDocumentDialogOpen(false);
        }
    };

    const openDropdownDialog = () => {
        setDropdownDialogOpen(true);
    };

    const closeImagePopup = (event, reason) => {
        if (reason !== 'backdropClick') {
            setDropdownDialogOpen(false);
            setImagePopupOpen(false);
        }
    };

    const sortContact = async () => {

        await getWhatsAppChatHistory('?take=25&requireTotalCount=true&isLoadingAll=true').then((res) => {
            if (res.success == true) {
                setWhatsAppContactList(res.data.data.filter((tag, index, array) => array.findIndex(t => t.mobile == tag.mobile) == index));
            }
        })

        await getCommunicationGroup('?take=25&requireTotalCount=true&isLoadingAll=true').then((res) => {
            if (res.success == true) {
                setWhatsAppGroupsList(res.data.data);
            }
        })

        if (props.currentChat.is_group) {
            await getWhatsAppUserChatHistory('?group_id=' + props.currentChat.id).then((res) => {
                if (res.success == true) {
                    let chatsArray = res.data.data.filter((elm) => { return elm.whatsapp_message != null || (elm.message_type == "image" || elm.message_type == "video" || elm.message_type == "document" || elm.message_type == "broadcast" || elm.message_type == "template") }).sort((a, b) => (new Date(a.updated_at).toLocaleString('en-IN')) - (new Date(b.updated_at).toLocaleString('en-IN'))).map(elm => ({ text: elm.whatsapp_message, message_type: elm.message_type, chat_id: elm.chat_id, document: elm.message_type == "document" ? elm.media_url_path : "", video: elm.message_type == "video" ? elm.media_url_path : "", image: elm.message_type == "image" ? elm.media_url_path : "", nature: elm.message_nature, chat_id: elm.chat_id, time: (new Date(elm.updated_at).toLocaleString('en-IN')) }));
                    setChatMessages(chatsArray);
                }
            })
        }
        else {
            await getWhatsAppUserChatHistory('?mobile=' + props.currentChat.mobile + '&latest_entry=true').then(async (res) => {
                if (res.success == true) {
                    if (res.data.data.length != 0) {
                        let newChat = res.data.data.filter((elm) => { return elm.whatsapp_message != null || (elm.message_type == "image" || elm.message_type == "video" || elm.message_type == "document" || elm.message_type == "broadcast" || elm.message_type == "template") }).sort((a, b) => (new Date(a.updated_at).toLocaleString('en-IN')) - (new Date(b.updated_at).toLocaleString('en-IN'))).map(elm => ({ text: elm.whatsapp_message, message_type: elm.message_type, chat_id: elm.chat_id, document: elm.message_type == "document" ? elm.media_url_path : "", video: elm.message_type == "video" ? elm.media_url_path : "", image: elm.message_type == "image" ? elm.media_url_path : "", nature: elm.message_nature, chat_id: elm.chat_id, time: (new Date(elm.updated_at).toLocaleString('en-IN')) }));
                        setChatMessages(prevState => [...prevState, newChat])
                        const formData = new FormData();
                        formData.append('chat_id', props.currentChat.id);
                        formData.append('mobile', props.currentChat.mobile);
                        await Caller(setIsRead, formData, POST, false, true)
                    }

                }
            })
        }
    }

    // Function to handle sending a message
    const handleSendMessage = async () => {
        setIsSendingMessageDisabled(true);
        if (!(newMessage.trim() || selectedImage || selectedVideo || selectedDocument)) {
            setIsSendingMessageDisabled(false);
            return; // No need to proceed if there's nothing to send
        } 
        setNewMessage('');
        const newMessageObj = {
            text: newMessage,
            image: selectedImage,
            imageName: imageName,
            video: selectedVideo,
            document: selectedDocument,
            nature: 'Outgoing',
            time: (new Date().toLocaleString('en-IN')),
            message_type: selectedImage ? 'image' : selectedVideo ? 'video' : selectedDocument ? 'document' : ""
        };

        let optmizedPhoneNumber;
        if (props.currentChat.mobile.length > 10) {
            let lastCharacters = props.currentChat.mobile.substr(props.currentChat.mobile.length - 10);
            optmizedPhoneNumber = "+91" + lastCharacters;
        }
        else {
            optmizedPhoneNumber = "+91" + props.currentChat.mobile;
        }

        const formData = new FormData();
        formData.append('whatsapp_contact_id', props.currentChat.id);
        formData.append('message_nature', 'Outgoing');
        formData.append('message', newMessage ?? "");

        if (imageId) {
            formData.append('image', imageId);
            formData.append('media_url_path', media_url_path ?? "");
        }
        if (documentId) {
            formData.append('document', documentId);
            formData.append('document_name', documentName ?? "");
            formData.append('media_url_path', media_url_path ?? "");
        }
        if (videoId) {
            formData.append('video', videoId);
            formData.append('media_url_path', media_url_path ?? "");
        }
        formData.append((mobileNumberArray.length > 0) ? 'mobile_number_array' : 'mobile', (mobileNumberArray.length > 0) ? mobileNumberArray : optmizedPhoneNumber);
        formData.append('message_type', (mobileNumberArray.length > 0) ? "broadcast" : "single");
        // Send msg api
        await Caller(sendMessage, formData, POST, false, true).then(async (res) => {
            if (res.success == true) {
                setSelectedImage(null);
                setImageId(null);
                setSelectedVideo(null);
                setVideoId('');
                setSelectedDocument(null);
                setDocumentId(null);
                sortContact();
                newMessageObj.media_id = (res.data?.saveData?.media_id || "");
                setChatMessages(prevMessages => [...prevMessages, newMessageObj]);
                setIsSendingMessageDisabled(false);
            }
            else {
                enqueueSnackbar('Message Couldn\'t Be Sent !', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } });
            }
        }).catch((error) => {
            if (error) {
                enqueueSnackbar('Message Couldn\'t Be Sent !', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" } })
            }
        });

        // clear the all state after sending msg
        closeImagePopup();
        closeVideoDialog();
        closeDocumentDialog();
    };

    // Function to handle canceling image selection

    const filterUserList = () => {
        return userList.filter((user) =>
            user.name.toLowerCase().includes(userSearchInput.toLowerCase())
        );
    };

    const filterGroupList = () => {
        return groupList.filter((group) =>
            group.name.toLowerCase().includes(userSearchInput.toLowerCase())
        );
    };

    const openDialog = () => {
        setDialogOpen(true);
    };

    const getAllGroupContact = async (newSelectedValue) => {
        const formData = new FormData();
        formData.append('id', newSelectedValue.id);
        await Caller(check_whatsapp_group_name, formData, POST, false, true).then((res) => {
            if (res.success == true) {
                setMobileNumberArray(res.data.map(({ mobile, ...rest }) => ({ mobile })).map(obj => obj.mobile))
            }
        })
    }

    function filterByValue(array, string) {
        return array.filter(o =>
            Object.keys(o).some(k => JSON.stringify(o[k]).toLowerCase().includes(string.toLowerCase())));
    }

    const searchChat = (e) => {
        setUserSearchInput(e.target.value);
        if (isChatIconClicked) {
            setWhatsAppContactSearchList(filterByValue(whatsAppContactList, e.target.value));
        }
        else if (isPeopleIconClicked) {
            setWhatsAppGroupsSearchList(filterByValue(whatsAppGroupsList, e.target.value));
        }
    }

    const downloadBtnClick = async (media_id, index) => {
        setLoadingIndex(index)
        setDownloadBtnLoader(true);
        const formData = new FormData();
        formData.append('media_id', media_id);

        await Caller(downloadMedia, formData, POST, false, true).then(async (response) => {

            let name = response.data.media_url_path;

            const response1 = await fetch(`${BASE_URL}${name}`);
            const pdfData = await response1.blob();
            const url = URL.createObjectURL(pdfData);

            let a = document.createElement('a');
            a.href = url;
            a.setAttribute(
                'download',
                name.split("/").pop(),
            );

            // Append to html link element page
            document.body.appendChild(a);

            // Start download
            a.click();

            // Clean up and remove the link
            a.parentNode.removeChild(a);

            // delete media after downloading
            const formData1 = new FormData();
            formData1.append('media_name', response.data.media_url_path);
            await Caller(deleteMedia, formData1, POST, false, true).then((res) => {
                setDownloadBtnLoader(false);
                setLoadingIndex(null)

            })

        })

    }

    return (

        <div>
            {/* </Grid> */}
            <Grid container component={Paper}>
                <Grid item xs={12} sm={3} md={3} lg={3} className={classes.borderRight500}>
                    <MDBox m={1}>
                        <TextField
                            id="user-search"
                            variant="outlined"
                            placeholder='Search......'
                            fullWidth
                            value={userSearchInput}
                            onChange={searchChat}
                        />
                    </MDBox>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item >
                                    <Fab
                                        className={`${isPeopleIconClicked ? classes.iconClicked : ''}`}
                                        color="info"
                                        disableRipple={isPeopleIconClicked}
                                        disabled={isPeopleIconClicked}
                                        aria-label="add"
                                        component="span"
                                        style={{ fontSize: "1.175rem" }}
                                        onClick={handleGroupIconClick}
                                    >
                                        <PeopleIcon />
                                    </Fab>
                                </Grid>
                                <Grid item >
                                    <Fab
                                        className={`${isChatIconClicked ? classes.iconClicked : ''}`}
                                        color="info"
                                        disableRipple={isChatIconClicked}
                                        disabled={isChatIconClicked}
                                        aria-label="add"
                                        component="span"
                                        style={{ fontSize: "1.175rem" }}
                                        onClick={handleChatIconClick}
                                    >
                                        <ChatIcon />
                                    </Fab>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <ScrollView width='100%' height='62vh'>
                        <List>
                            {isChatIconClicked &&
                                (whatsAppContactSearchList.length !== 0 ? whatsAppContactSearchList : whatsAppContactList)?.map((value, index) => (
                                    <ListItemButton
                                        key={index}
                                        onClick={() => {
                                            setIsLoading(true);
                                            props.setProfile(value);
                                        }}
                                        selected={(value.mobile == props.currentChat.mobile) || false}
                                        sx={{
                                            transition: "0.25s",
                                            '&.Mui-selected,&.Mui-selected:hover': {
                                                backgroundColor: 'rgba(77, 168, 81, 0.2)',
                                                transition: "0.25s",
                                            },// Apply the same styling to the input element
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Avatar
                                                alt={value.first_name}
                                                src={`https://material-ui.com/static/images/avatar/alt.jpg`}
                                                variant='rounded'
                                                sx={{ mr: '0.5' }}
                                            />
                                        </ListItemIcon >
                                        <Typography
                                            sx={{ fontSize: "0.875rem !important", color: "#7b809a" }}
                                            primary={value.first_name}
                                        >
                                            {value.first_name} ({value.mobile})
                                        </Typography>
                                    </ListItemButton >
                                ))
                            }
                            {
                                isPeopleIconClicked &&
                                (whatsAppGroupsSearchList.length !== 0 ? whatsAppGroupsSearchList : whatsAppGroupsList)?.map((value, index) => (

                                    <ListItemButton
                                        key={index}
                                        onClick={() => {
                                            setIsLoading(true);
                                            let newSelectedValue = {
                                                id: value.id,
                                                first_name: value.whatsapp_group_name,
                                                mobile: value.whatsapp_group_name,
                                                is_group: true
                                            }
                                            props.setProfile(newSelectedValue);
                                            getAllGroupContact(newSelectedValue);
                                        }}
                                        selected={(value.whatsapp_group_name == props.currentChat.mobile) || false}
                                        sx={{
                                            '&.Mui-selected,&.Mui-selected:hover': {
                                                backgroundColor: 'rgba(77, 168, 81, 0.2)',
                                            },// Apply the same styling to the input element
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Avatar
                                                alt={value.whatsapp_group_name}
                                                src={`https://material-ui.com/static/images/avatar/alt.jpg`}
                                                variant='rounded'
                                                sx={{ mr: '0.5' }}
                                            />
                                        </ListItemIcon>
                                        <Typography
                                            sx={{ fontSize: "0.875rem !important", color: "#7b809a" }}
                                            primary={value.whatsapp_group_name}
                                        >
                                            {value.whatsapp_group_name}
                                        </Typography>
                                    </ListItemButton>
                                ))
                            }
                        </List >
                    </ScrollView >
                </Grid >
                <Grid item xs={12} sm={9} md={9} lg={9}>
                    <Grid container style={{ padding: '20px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            {isLoading &&
                                <div className='alert alert-light' style={{ padding: "30vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ClipLoader
                                        height="80"
                                        width="80"
                                        ariaLabel="progress-bar-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="progress-bar-wrapper"
                                        borderColor='#F4442E'
                                        barColor='#51E5FF'
                                    />

                                </div>
                            }
                            {!isLoading && <ScrollView width='100%' height='65vh' ref={scrollViewRef}>
                                <List>
                                    {chatMessages?.map((message, index) => (
                                        <ListItem ref={chatHeightRef} key={index}>
                                            <Grid container>
                                                <Grid item xs={12} sx={{ justifyContent: (message.nature === 'Incoming') ? 'flex-end' : 'flex-start', alignItems: 'center', display: 'flex' }}>
                                                    {(message.message_type == "image" || message.message_type == "video" || message.message_type == "document") && message.nature == 'Incoming' && (
                                                        <>
                                                            {loadingIndex === index ? <PuffLoader
                                                                color="#36D7B7"
                                                                loading={downloadBtnLoader}
                                                                size={50}
                                                            /> : <IconButton onClick={() => downloadBtnClick(message.media_id, index)} style={{ fontSize: '5vh', color: "#1A73E8" }}>
                                                                <DownloadIcon />
                                                            </IconButton>}
                                                        </>
                                                    )}
                                                    <Paper
                                                        elevation={3}
                                                        variant="outlined"
                                                        style={{
                                                            padding: '3px 12px 3px 12px',
                                                            margin: '8px',
                                                            fontSize: "10px",
                                                            fontFamily: 'inherit',
                                                            color: 'white',
                                                            backgroundImage: (message.nature == 'Incoming') ? "linear-gradient(to right, #4cb8c4, #3cd3ad)" : "linear-gradient(to right, #ff512f, #f09819)",
                                                            borderRadius: (message.nature == 'Incoming') ? "20px 20px 2px 20px" : "20px 20px 20px 2px",
                                                            border: 0,
                                                            display: 'inline-block',
                                                            maxWidth: "48%",
                                                            overflowWrap: "break-word"
                                                        }}
                                                    >

                                                        {/* Display the selected image if present */}
                                                        {(message.message_type == "image" || message.message_type == "video" || message.message_type == "document") && (

                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {message.message_type == "image" && <PermMediaIcon
                                                                    sx={{ fontSize: '5vh !important', color: "white", my: 1, mr: 1 }}
                                                                />}
                                                                {message.message_type == "video" && <VideoFileIcon
                                                                    sx={{ fontSize: '5vh !important', color: "white", my: 1, mr: 1 }}
                                                                />
                                                                }
                                                                {message.message_type == "document" && <PictureAsPdfIcon
                                                                    sx={{ fontSize: '5vh !important', color: "white", my: 1, mr: 1 }}
                                                                />
                                                                }

                                                                <Typography sx={{ color: '#fffefc', fontSize: "small" }}>
                                                                    {message.media_id ? message.media_id + (message.message_type == "image" ? ".jpg" : (message.message_type == "video" ? ".mp4" : '.pdf')) : (message.message_type == "image" ? tempMediaId + ".jpg" : (message.message_type == "video" ? tempMediaId + ".mp4" : tempMediaId + ".pdf"))}
                                                                </Typography>
                                                            </div>
                                                        )}

                                                        {message.chat_id && message.message_type == "template" && (
                                                            <>
                                                                {Array.from(new Set(metaImageUrl.map(JSON.stringify))).map(JSON.parse).map((imgUrl, index) => {
                                                                    if (message.chat_id == Object.keys(imgUrl)) {
                                                                        if (Object.values(imgUrl) != '') {
                                                                            return (<img
                                                                                key={index}
                                                                                src={Object.values(imgUrl)}
                                                                                alt="Description of the image"
                                                                                style={{ maxWidth: '100%', borderRadius: '20px', paddingTop: "8px" }}
                                                                            />)
                                                                        } else {
                                                                            return <div>{ }</div>
                                                                        }
                                                                    }
                                                                })}

                                                            </>
                                                        )}

                                                        {/* Display the message text */}
                                                        {message.text && (
                                                            <ListItemText align="left"
                                                                primary={message.text.split('\n').map((line, lineIndex) => (
                                                                    <div key={lineIndex} style={{ margin: '10px 10px 3px 10px' }}>{line ? line.replace(/<\/?[^>]+(>|$)|&[a-zA-Z0-9]+;/g, "") : ""}</div>
                                                                ))}
                                                                primaryTypographyProps={{ fontSize: "medium" }}
                                                            />

                                                        )}

                                                        <Divider light variant="inset" sx={{ m: '0 0 0.5rem 0' }} />
                                                        <ListItemText align={(message.nature == 'Incoming') ? "right" : "left"}
                                                            primary={message.time}
                                                            primaryTypographyProps={{ fontSize: "x-small" }}
                                                            style={{ margin: '0px 10px 3px 10px' }} />
                                                    </Paper>

                                                    {(message.message_type == "image" || message.message_type == "video" || message.message_type == "document") && message.nature == 'Outgoing' && (
                                                        <>
                                                            {loadingIndex === index ? <PuffLoader
                                                                color="#36D7B7"
                                                                loading={downloadBtnLoader}
                                                                size={50}
                                                            /> : <IconButton onClick={() => downloadBtnClick(message.media_id, index)} style={{ fontSize: '5vh', color: "#1A73E8" }}>
                                                                <DownloadIcon />
                                                            </IconButton>}
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    ))}
                                </List>
                            </ScrollView>}
                        </Grid>

                        {!isLoading && <Grid item xs={12} sm={9} md={9} lg={9}><fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                            <ThemeProvider theme={theme}>
                                <ScrollView height='100%' width='100%'>
                                    <TextField
                                        id="outlined-basic-email"
                                        placeholder='Type a message'
                                        color='inherit'
                                        variant='filled'
                                        fullWidth
                                        // style={{width:"31rem"}}
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                handleSendMessage();
                                            }
                                        }}
                                        multiline
                                        className={classes.textField}
                                        InputProps={{
                                            classes: {
                                                input: classes.scrollableTextArea, // Apply the same styling to the input element
                                            },

                                        }}
                                    />
                                </ScrollView>
                                <Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseEmojiPicker}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <EmojiPicker onEmojiClick={(data) => setNewMessage((prevMessage) => prevMessage + data.emoji)} />
                                </Popover>
                            </ThemeProvider></fieldset>
                        </Grid>}

                        {!isLoading && <Grid item xs={12} sm={3} md={3} lg={3} align="center">
                            <fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                                <Fab color="info" aria-label="add" onClick={handleEmojiClick} sx={{ fontSize: "25px" }}>
                                    <EmojiEmotionsIcon />
                                </Fab>
                                <Fab color="info" aria-label="add" onClick={openDropdownDialog} sx={{ ml: 1 }}>
                                    <AttachFileIcon />
                                </Fab>
                                <Fab color="info" aria-label="add" onClick={openDialog} sx={{ ml: 1, mr: 1 }}>
                                    <AttachEmailIcon />
                                </Fab>
                                <Fab color="info" aria-label="add" onClick={handleSendMessage}>
                                    <SendIcon />
                                </Fab>
                            </fieldset>
                        </Grid>}


                    </Grid>
                </Grid>
            </Grid >

            <Dialog
                open={isDialogOpen}
                // onClose={closeDialog}
                fullWidth
                maxWidth="lg"
                disableEnforceFocus={true}
            >
                <DialogTitle
                ><MDBox
                    color="white"
                    bgColor="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    p={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                // textAlign="center"
                >
                        <MDTypography variant="h4" color="white">Choose Template</MDTypography>
                        <IconButton
                            style={{ color: "white", cursor: "pointer" }}
                            sx={{
                                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" }
                            }}
                            onClick={() => setDialogOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </MDBox></DialogTitle>
                <DialogContent>
                    <AddTemplates chatData={props.currentChat.mobile} onClose={handleClose} sortContact={sortContact} mobileArray={mobileNumberArray}
                        setVariableValue={(newValue) => {
                            // setAllValues((values) => ({
                            //     ...values,
                            //     variable_value: newValue,
                            // }));

                            setVariableValue(newValue)
                        }}

                        setChat={(value, template_id) => {
                            let imgurl = metaImageUrl.find(obj => Object.keys(obj) == template_id)
                            let newMessageObj = {
                                text: value,
                                nature: 'Outgoing',
                                time: (new Date().toLocaleString('en-IN'))
                            };
                            if (imgurl) {
                                newMessageObj.image = Object.values(imgurl);
                            }
                            setChatMessages(prevMessages => [...prevMessages, newMessageObj]);
                        }} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={isDropdownDialogOpen}
                onClose={() => setDropdownDialogOpen(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogContent style={{
                    // paddingTop: 8
                }}>
                    {/* Add your list of dropdowns or any content here */}
                    <List style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <label htmlFor='image-upload'>
                            <ListItem
                                // button
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: '2px solid #ccc',
                                    borderRadius: '4px',
                                    justifyContent: "center",
                                    width: "11rem",
                                    cursor: 'pointer',
                                    padding: "10px"
                                }}
                            >
                                <input
                                    type="file"
                                    id="image-upload"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />

                                <ListItemAvatar>

                                    <Fab color="primary" aria-label="add" component="span" style={{ fontSize: "1.875rem" }}>
                                        <ImageIcon />
                                    </Fab>

                                </ListItemAvatar>

                                <ListItemText sx={{ color: 'grey', '& .css-6cmau2-MuiTypography-root': { fontSize: "0.875rem", color: "#7b809a", lineHeight: "0.9", marginTop: 1 } }} primary="Images" />
                            </ListItem>
                        </label>

                        <label htmlFor='video-upload'>
                            <ListItem
                                button
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: '2px solid #ccc',
                                    borderRadius: '4px',
                                    justifyContent: "center",
                                    cursor: 'pointer',
                                    width: "11rem",
                                    padding: "10px"
                                }}
                            >
                                <input
                                    type="file"
                                    id="video-upload"
                                    accept="video/*"
                                    style={{ display: 'none' }}
                                    onChange={handleVideoChange}
                                />
                                <ListItemAvatar>
                                    <Fab color="secondary" aria-label="add" component="span" style={{ fontSize: "1.875rem" }}>
                                        <VideoLabelIcon />
                                    </Fab>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: 'grey', '& .css-6cmau2-MuiTypography-root': { fontSize: "0.875rem", color: "#7b809a", lineHeight: "0.9", marginTop: 1 } }} primary="Videos" />
                            </ListItem>
                        </label>
                        <label htmlFor='document-upload'>
                            <ListItem
                                // button
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: '2px solid #ccc',
                                    borderRadius: '4px',
                                    justifyContent: "center",
                                    cursor: 'pointer',
                                    width: "11rem",
                                    padding: "10px"
                                }}
                            >
                                <input
                                    type="file"
                                    id="document-upload"
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    onChange={handleDocumentChange}
                                />
                                <ListItemAvatar>
                                    <Fab aria-label="add" component="span" style={{ backgroundColor: "#4caf50", color: "white", fontSize: "1.875rem" }} >
                                        <DescriptionIcon />
                                    </Fab>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: 'grey', '& .css-6cmau2-MuiTypography-root': { fontSize: "0.875rem", color: "#7b809a", lineHeight: "0.9", marginTop: 1 } }} primary="Documents" />
                            </ListItem>
                        </label>
                    </List>
                </DialogContent>
            </Dialog>

            {/* Image Dialog */}
            <Dialog
                open={isImagePopupOpen}
                onClose={closeImagePopup}
                maxWidth="sm"
            >
                <DialogTitle style={{ position: 'absolute', top: 2, left: 3 }}>
                    <fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                        <IconButton onClick={closeImagePopup} color="info">
                            <CancelIcon />
                        </IconButton>
                    </fieldset>
                </DialogTitle>
                <DialogContent
                    style={{
                        paddingTop: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    {isImageSelected && (
                        <>
                            <img
                                src={selectedImage}
                                alt="Selected"
                                style={{ maxWidth: '50%', maxHeight: '100%', zIndex: 10, WebkitFilter: loader ? "brightness(50%)" : "brightness(100%)", WebkitTransition: ".3s all" }}
                            />
                            <BeatLoader
                                color={'#36d7b7'}
                                loading={loader}
                                style={{ position: "absolute", zIndex: 11 }}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />

                        </>
                    )}
                </DialogContent>


                <DialogActions style={{ marginBottom: 15, width: "100%" }}>
                    <fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                        <ThemeProvider theme={theme}>
                            <TextField
                                id="outlined-basic-image"
                                // sx={{ '& .css-10botns-MuiInputBase-input-MuiFilledInput-input': { fontSize: "0.875rem", color: "#7b809a", lineHeight: "0.9", padding: "0px", height: "2.275rem", margin: "5px" } }}
                                placeholder="Add a comment"
                                color='inherit'
                                variant='filled'
                                fullWidth
                                style={{ width: '31rem' }}
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSendMessage();
                                    }
                                }}
                                multiline
                                disabled={loader}
                            />
                        </ThemeProvider>
                        <IconButton onClick={handleSendMessage} color="info" disabled={loader}>
                            <SendIcon />
                        </IconButton>
                    </fieldset>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isVideoDialogOpen}
                onClose={closeVideoDialog}
                maxWidth="sm"
            >
                <DialogTitle style={{ position: 'absolute', top: 2, left: 3 }}>
                    <fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                        <IconButton onClick={closeVideoDialog} color="info">
                            <CancelIcon />
                        </IconButton>
                    </fieldset>
                </DialogTitle>
                <DialogContent
                    style={{
                        paddingTop: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {isSelectedVideo && (
                        <>
                            <video controls style={{ maxWidth: '50%', maxHeight: '50%', zIndex: 10, WebkitFilter: loader ? "brightness(50%)" : "brightness(100%)", WebkitTransition: ".3s all" }}>
                                <source src={selectedVideo} type="video/mp4" />

                            </video>
                            <BeatLoader
                                color={'#36d7b7'}
                                loading={loader}
                                style={{ position: "absolute", zIndex: 11 }}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </>
                    )}
                </DialogContent>

                <DialogActions style={{ marginBottom: 15, width: "100%" }}>
                    <fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                        <ThemeProvider theme={theme}>
                            <TextField
                                id="outlined-basic-video"
                                placeholder="Add a comment"
                                color='inherit'
                                variant='filled'
                                fullWidth
                                style={{ width: '31rem' }}
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSendMessage();
                                    }
                                }}
                                multiline
                            />
                        </ThemeProvider>
                        <IconButton onClick={handleSendMessage} color="info">
                            <SendIcon />
                        </IconButton>
                    </fieldset>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDocumentDialogOpen}
                onClose={closeDocumentDialog}
                maxWidth="sm"

            >
                <DialogTitle style={{ marginBottom: 15, top: 2, left: 3 }}>
                    <fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                        <IconButton onClick={closeDocumentDialog} color="info">
                            <CancelIcon />
                        </IconButton>
                    </fieldset>
                </DialogTitle>

                <DialogContent
                    style={{
                        paddingTop: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: 'column'

                    }}
                >
                    {isSelectedDocument && (
                        <>
                            <InsertDriveFileIcon

                                sx={{ fontSize: '60px !important', color: 'green', zIndex: 10, WebkitFilter: loader ? "brightness(50%)" : "brightness(100%)", WebkitTransition: ".3s all" }}
                            />
                            <Typography variant="body1" style={{ fontSize: "0.875rem", color: "#7b809a", lineHeight: "0.9" }}>
                                {documentName}
                            </Typography>
                            <BeatLoader
                                color={'#36d7b7'}
                                loading={loader}
                                style={{ position: "absolute", zIndex: 11 }}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </>
                    )}
                </DialogContent>

                <DialogActions style={{ marginBottom: 15, width: "100%" }}>
                    <fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                        <ThemeProvider theme={theme}>
                            <TextField
                                id="outlined-basic-document"
                                placeholder="Add a comment"
                                color='inherit'
                                variant='filled'
                                fullWidth
                                style={{ width: '31rem' }}
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSendMessage();
                                    }
                                }}
                                multiline
                            />
                        </ThemeProvider>
                        <IconButton onClick={handleSendMessage} color="info">
                            <SendIcon />
                        </IconButton>
                    </fieldset>
                </DialogActions>
            </Dialog>

            {/* Image Dialog */}
            <Dialog
                open={isImageDialogOpen}
                // onClose={closeImageDialog}
                maxWidth="sm"


            >
                <DialogTitle >
                    {/* Add a close button in the dialog title */}
                    <fieldset disabled={isSendingMessageDisabled} style={{ border: 'none', padding: 0, margin: 0 }}>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={closeImageDialog}
                            aria-label="close"
                            sx={{ position: 'absolute', top: 5, right: 5 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </fieldset>
                </DialogTitle>
                <DialogContent

                    style={{
                        paddingTop: "10px",
                        paddingBottom: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",


                    }}
                >
                    {/* Use the Zoom component to display the image with zooming */}
                    <Zoom in={true}>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{ maxWidth: '50%', maxHeight: '50%', overflowY: "hidden" }}
                        />
                    </Zoom>

                </DialogContent>
            </Dialog>

            <Dialog open={openVideoClickDialog} maxWidth="sm">
                <DialogTitle>
                    {/* Add a close button in the dialog title */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={closeVideoClick}
                        aria-label="close"
                        sx={{ position: 'absolute', top: 5, right: 5 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    style={{
                        paddingTop: "10px",
                        paddingBottom: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                    }}
                >
                    <VideoViewer videoUrl={selectedVideo} />
                </DialogContent>
            </Dialog>
        </div >
    );
}

export default ChatPage;