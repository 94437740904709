/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import Dashboard from "layouts/dashboard";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE, RIGHTS, EMP_USER_ID } from 'Apis/config/ResponseHandler';

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { Collapse, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { permissions } from "Apis/Auth/auth";
import Cookies from "js-cookie";
import TextsmsIcon from '@mui/icons-material/Textsms';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import AssistantIcon from '@mui/icons-material/Assistant';
import MessageIcon from '@mui/icons-material/Message';
import CampaignIcon from '@mui/icons-material/Campaign';
import { ScrollView } from "devextreme-react";
import { WHATSAPP_REDIRECT_URL } from "Apis/config/ResponseHandler";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [openMasterData, setOpenMasterData] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openCommunication, setOpenCommunication] = useState(false);


  const [openStock, setOpenStock] = useState(false);


  let rights = Cookies.get(RIGHTS) ?? "";
  let rightsArray = rights.split(',') ?? [];

  const handleMasterClick = () => {
    setOpenMasterData(!openMasterData);
  };
  const handleUserClick = () => {
    setOpenUsers(!openUsers);
  };


  const handleStockClick = () => {
    setOpenStock(!openStock);
  };

  const handleContact = () => {
    setOpenCommunication(!openCommunication);
  }

  const handleWhatsapp = () => {
    // setOpenCommunication(!openCommunication);

    let luc = Cookies.get(LOGIN_USERTYPE_COOKIE)
    let lac = Cookies.get(LOGIN_AUTH_COOKIE)
    let lrc = Cookies.get(LOGIN_REFRESH_COOKIE)
    let right = Cookies.get(RIGHTS)
    let eui = Cookies.get(EMP_USER_ID)

    window.open(`${WHATSAPP_REDIRECT_URL}?LOGIN_USERTYPE_COOKIE=${luc}&LOGIN_AUTH_COOKIE=${lac}&LOGIN_REFRESH_COOKIE=${lrc}&r=${right}&eui=${eui}`, '_blank');

  }

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth > 1200 ? true : miniSidenav);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <ScrollView height="100%">
        <MDBox pt={3} pb={1} px={4} textAlign="center">
          <MDBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="h6" color="secondary">
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </MDTypography>
          </MDBox>
          <MDBox component={NavLink} to="/dashboard" display="flex" alignItems="center" justifyContent="center" sx={{ backgroundColor: "white" }}>
            {brand && <MDBox component="img" src={brand} alt="Brand" width={miniSidenav ? "2rem" : "5rem"} sx={{ transition: "0.3s" }} />}
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <List sx={{ width: (miniSidenav && ("sales" === collapseName || "all_payment" === collapseName || "product-assignment" === collapseName || "dashboard" === collapseName)) ? "65%" : "86%" }}>
          {(rightsArray.includes('108')) && !rightsArray.includes('110') && <NavLink
            key="sales_crm"
            to="/sales"
            sx={{ textDecoration: "none" }}>
            <SidenavCollapse
              name="Sales CRM"
              icon='person_add'
              active={"sales" === collapseName}
            />
          </NavLink>}

          {(rightsArray.includes('126')) && <NavLink
            key="all_payments"
            to="/all_payment"
            sx={{ textDecoration: "none" }}>
            <SidenavCollapse
              name="Payments"
              // icon='person_add'
              icon={<CurrencyRupeeIcon />}
              active={"all_payment" === collapseName}
            />
          </NavLink>}

          {/* {(rightsArray.includes('108')) && !rightsArray.includes('110') && <NavLink
        key="Communications"
        to="/communications"
        sx={{ textDecoration: "none" }}>
        <SidenavCollapse
          name="Communications"
          // icon='person_add'
          icon={<ChatBubbleOutlineIcon />}
          active={"communications" === collapseName}
        />
      </NavLink>} */}

          {/* {(rightsArray.includes('108') || rightsArray.includes('109') || rightsArray.includes('113')) && !rightsArray.includes('110') &&  */}
          <SidenavCollapse
            name="Communications"
            icon={<EmailIcon />}
            onClick={handleContact}
            active={"communications" === collapseName}
          >
            {openCommunication || "assignments" === collapseName || "communicationContacts" === collapseName || "chat" === collapseName || "broadcast" === collapseName ? <ExpandLess /> : <ExpandMore />}
          </SidenavCollapse>
          {/* } */}
          <Collapse in={openCommunication || "assignments" === collapseName || "communicationContacts" === collapseName || "chat" === collapseName || "broadcast" === collapseName} timeout="auto" unmountOnExit>
            <List component="div" sx={{ pl: 2 }}>
              {/* {(rightsArray.includes('116')) &&
                <NavLink key="assignments" to="/assignments"
                  sx={{ textDecoration: "none" }}>
                  <SidenavCollapse
                    name="Assignments"
                    icon={<AssignmentTurnedInIcon />}
                    active={"assignments" === collapseName}
                  />
                </NavLink>
              } */}
              {/* {(rightsArray.includes('109')) && !rightsArray.includes('110') &&  */}
              <NavLink key="communicationContacts" to="/communicationContacts"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Contacts"
                  icon={<ContactMailIcon />}
                  active={"communicationContacts" === collapseName}
                />
              </NavLink>

              {/* <NavLink key="chat" to="/chat"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Chat"
                  icon={<MessageIcon />}
                  active={"chat" === collapseName}
                />
              </NavLink> */}

              {/* <NavLink key="broadcast" to="/broadcast"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Broadcast"
                  icon={<CampaignIcon />}
                  active={"broadcast" === collapseName}
                />
              </NavLink> */}
              {/* } */}

            </List>
          </Collapse>


          {(rightsArray.includes('100')) && !rightsArray.includes('110') && <NavLink key="product-assignment" to="/product-assignment"
            sx={{ textDecoration: "none" }}>
            <SidenavCollapse
              name="Product Assignment"
              icon="people"
              active={"product-assignment" === collapseName}
            />
          </NavLink>}

          <NavLink
            key="dashboard"
            to="/dashboard"
            sx={{ textDecoration: "none" }}>
            <SidenavCollapse
              name="Dashboard"
              icon="home"
              active={"dashboard" === collapseName}
            />
          </NavLink>
          {(rightsArray.includes('101') || rightsArray.includes('102') || rightsArray.includes('103')) && !rightsArray.includes('110') && <SidenavCollapse
            name="Settings"
            icon="settings"
            onClick={handleMasterClick}
            active={"settings" === collapseName}
          >
            {openMasterData || "lookup" === collapseName || "emailtemplates" === collapseName ? <ExpandLess /> : <ExpandMore />}
          </SidenavCollapse>}
          <Collapse in={openMasterData || "lookup" === collapseName || "emailtemplates" === collapseName} timeout="auto" unmountOnExit>
            <List component="div" sx={{ pl: 2 }} disablePadding>
              {(rightsArray.includes('101')) && !rightsArray.includes('110') && <NavLink key="lookup_options" to="/lookup"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Lookup Options"
                  icon="dns"
                  active={"lookup" === collapseName}
                />
              </NavLink>}
              {(rightsArray.includes('102')) && !rightsArray.includes('110') && <NavLink key="emailtemplates" to="/emailtemplates"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Email Templates"
                  icon="attach_email"
                  active={"emailtemplates" === collapseName}
                />
              </NavLink>}
            </List>
          </Collapse>

          {(rightsArray.includes('104')) && !rightsArray.includes('110') &&
            <SidenavCollapse
              name="Organization"
              icon="factory"
              onClick={handleUserClick}
            >
              {openUsers || "employees" === collapseName || "employees/create" === collapseName || "bulk-employee-upload" === collapseName || "bulk-employee-upload/update/errors" === collapseName ? <ExpandLess /> : <ExpandMore />}
            </SidenavCollapse>
          }
          <Collapse in={openUsers || "employees" === collapseName || "employees/create" === collapseName || "bulk-employee-upload" === collapseName || "bulk-employee-upload/update/errors" === collapseName} timeout="auto" unmountOnExit>
            <List component="div" sx={{ pl: 2 }} >

              {(rightsArray.includes('104')) && !rightsArray.includes('110') && <NavLink key="employees" to="/employees"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Employees"
                  icon="people"
                  active={"employees" === collapseName || "employees/create" === collapseName}
                />
              </NavLink>}

              {(rightsArray.includes('105')) && !rightsArray.includes('110') && <NavLink key="designation" to="/designations"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Designations"
                  icon="people"
                  active={"designations" === collapseName}
                />
              </NavLink>}

              {(rightsArray.includes('106')) && !rightsArray.includes('110') && <NavLink key="departments" to="/departments"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Departments"
                  icon="people"
                  active={"departments" === collapseName}
                />
              </NavLink>}
              {(rightsArray.includes('107')) && !rightsArray.includes('110') && <NavLink
                key="bulk-employee-upload"
                to="/bulk-employee-upload"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Bulk Employee Upload"
                  icon="drive_folder_upload"
                  active={"bulk-employee-upload" === collapseName || "bulk-employee-upload/update/errors" === collapseName}
                />
              </NavLink>}

            </List>
          </Collapse>



          {/* {(rightsArray.includes('101') || rightsArray.includes('102') || rightsArray.includes('103') || rightsArray.includes('127')) && !rightsArray.includes('110') && <SidenavCollapse
            name="Stock Option"
            icon="factory"
            onClick={handleStockClick}
          >
            {openStock || "calls" === collapseName || "symbols" === collapseName ? <ExpandLess /> : <ExpandMore />}
          </SidenavCollapse>}
          <Collapse in={openStock || "calls" === collapseName || "symbols" === collapseName} timeout="auto" unmountOnExit>
            <List component="div" sx={{ pl: 2 }} >

              {(rightsArray.includes('101')) && !rightsArray.includes('110') && <NavLink key="calls" to="/calls"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Calls"
                  icon="people"
                  active={"calls" === collapseName}
                />
              </NavLink>}

              {(rightsArray.includes('109')) && !rightsArray.includes('110') && <NavLink key="symbols" to="/symbols"
                sx={{ textDecoration: "none" }}>
                <SidenavCollapse
                  name="Symbols"
                  icon="S"
                  active={"symbols" === collapseName}

                />
              </NavLink>}


            </List>
          </Collapse> */}



          <SidenavCollapse
            name="WhatsApp"
            icon={<EmailIcon />}
            onClick={handleWhatsapp}
            active={"communications" === collapseName}
          >
            {openCommunication || "assignments" === collapseName || "communicationContacts" === collapseName || "chat" === collapseName || "broadcast" === collapseName ? <ExpandLess /> : <ExpandMore />}
          </SidenavCollapse>

          <Collapse in={openCommunication || "assignments" === collapseName || "communicationContacts" === collapseName || "chat" === collapseName || "broadcast" === collapseName} timeout="auto" unmountOnExit>
            <List component="div" sx={{ pl: 2 }}>

            </List>
          </Collapse>

        </List>
      </ScrollView>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
