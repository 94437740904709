/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Switch, InputLabel, Autocomplete, TextField, FormHelperText, CardHeader, CardContent, Typography } from '@mui/material'
import MDButton from 'components/MDButton'
import { Button, Column, DataGrid, Editing, Export, Item, Paging, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { Pager } from 'devextreme-react/tree-list';
import React, { useRef, useState, useEffect } from 'react'
import Caller from 'Apis/config/Caller';
import { POST, PUT, GET } from 'Apis/config/RequestType';
import { get_lead_employees_name } from 'Apis/config/Url';
import { update_bulk_lead_assign_mode } from 'Apis/config/Url';
import { useSnackbar } from "notistack";
import { lead_assignment_log } from 'Apis/config/Url';


const newLeadSelectionFilter = ['first_name', 'notcontains', 'null'];
const showSelectedLeads = (props) => {
    let leadData = props.selectedRows;
    const userGrid = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedData, setSelectedData] = useState(null);

    const [values, setAllValues] = useState({
        assigned_to_id: "",
        assigned_to_value: "",
        team_members: [],
    })
    const [errors, setAllErrors] = useState({
        assigned_to: "",
    })

    useEffect(() => {
        // get the team leader and team member list in dropdown.
        Caller(get_lead_employees_name, "", GET, false, true).then(async (res) => {
            if (res.success === true) {
                setAllValues((values) => ({
                    ...values,
                    team_members: res.data.data
                }))
            }
        });
    }, [])


    const onSelectionChanged = (e) => {
        e.component.getSelectedRowsData().then((data) => {
            setSelectedData(data);
        })

    }


    const selectAssignedTo = (e, newValue, clickType) => {

        setAllErrors((errors) => ({
            ...errors,
            assigned_to: ""
        }))
        if (clickType == 'clear') {
            setAllValues((values) => ({
                ...values,
                assigned_to_id: "",
                assigned_to_val: "",
            }));
        } else {
            let data = values.team_members?.find(o => o.employee_name == newValue);

            setAllValues((values) => ({
                ...values,
                assigned_to_value: newValue ? newValue : "",
                assigned_to_id: data ? data.employee_user_id : ''
            }));
            setAllErrors((errors) => ({
                ...errors,
                assigned_to_id: ""
            }))
        }

    }

    // Update Data
    const updateLeadData = async (event) => {
        let lead_guid_array = selectedData ? selectedData.map(value => value.lead_guid) : leadData.map(value => value.lead_guid);
        let lead_id_array = selectedData ? selectedData.map(value => value.id) : leadData.map(value => value.id);

        let previous_assigned_to_array = selectedData ? selectedData.map(value => { 
            return value.id;
        }) : leadData.map(value => {
            return value.assigned_to;
        });

        const formData = new FormData();
        formData.append("lead_guid", lead_guid_array);
        formData.append("assigned_to", values.assigned_to_id);
        await Caller(update_bulk_lead_assign_mode, formData, POST, false, true).then(async (res) => {
            if (res.success === true) {
                enqueueSnackbar("Lead Assigned Successfully.", {
                    variant: "success",
                    autoHideDuration: 2000,
                    TransitionProps: { direction: "left" },
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                });

                const formData = new FormData();
                formData.append('lead_id', lead_id_array)
                formData.append('previous_assign_to', previous_assigned_to_array)
                formData.append("changed_assign_to", values.assigned_to_id);

                await Caller(lead_assignment_log, formData, POST, false, true).then((res) => {
                    if (res.success === true) {
                        props.refresh();
                    }
                });
                
                props.refresh();
                props.onClose();

            } else {
                for (const [key, value] of Object.entries(res.error)) {
                    setAllErrors((values) => ({
                        ...values,
                        [`${key}`]: value,
                    }))
                }
            }
        })

    }

    // disable update button when value is empty
    const checkDisabled = () => {
        if (values.assigned_to_id != '' && selectedData != '') {
            return false;
        } else {
            return true;
        }
    }

    // Cancel btn click.
    const cancel = () => {
        props.onClose();
    };

    // Render ID column
    const renderID = (data) => {
        return <div>{"FD-" + data.data.id}</div>
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} md={8}>
                    <Card>
                        <CardHeader title="Leads" sx={{ px: 3, pb: 0 }}>
                        </CardHeader>
                        <CardContent>
                            <DataGrid
                                id="gridContainer"
                                dataSource={leadData}
                                keyExpr="id"
                                ref={userGrid}
                                showBorders={true}
                                remoteOperations={true}
                                wordWrapEnabled={true}
                                showColumnLines={false}
                                rowAlternationEnabled={true}
                                defaultSelectionFilter={newLeadSelectionFilter}
                                onSelectionChanged={onSelectionChanged}
                                height="38vh" // Set the desired height here
                            >
                                <Pager
                                    visible={true}
                                    displayMode="full"
                                    showInfo={true}
                                // showNavigationButtons={true}
                                />
                                <Paging
                                    enabled={false}
                                />

                                <Selection
                                    mode="multiple"
                                    showCheckBoxesMode="always"
                                    deferred={true}
                                />

                                <Column
                                    caption="ID"
                                    dataField="id"
                                    alignment="center"
                                    cellRender={renderID}
                                    minWidth={20}
                                />
                                <Column
                                    caption="First Name"
                                    dataField="first_name"
                                    alignment="center"
                                    minWidth={100}
                                />
                                <Column
                                    caption="Mobile"
                                    dataField="mobile"
                                    alignment="center"
                                    minWidth={100}
                                />
                                <Column
                                    caption="Email"
                                    dataField="email"
                                    alignment="center"
                                    minWidth={100}
                                />
                            </DataGrid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item sm={12} xs={12} md={4}>
                    <Card style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh"
                    }}>
                        <CardHeader title="Assign To" sx={{ px: 3, pb: 0 }}>
                        </CardHeader>
                        <CardContent>
                            <Autocomplete
                                name="assigned_to"
                                value={values.assigned_to_value}
                                style={{ width: "17vw" }}
                                onChange={selectAssignedTo}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" />
                                )}
                                error={!!errors.assigned_to}
                                options={values.team_members?.map((value) => {
                                    return value.employee_name ?? "";
                                })}
                                sx={{
                                    ".MuiOutlinedInput-root": { padding: "0px", height: 45 }
                                }}
                            />
                            <FormHelperText error>{errors.assigned_to === "" ? '' : errors.assigned_to}</FormHelperText>

                        </CardContent>
                    </Card>
                </Grid>

                <DialogActions
                    sx={{
                        background: "linear-gradient(195deg, #dee1e1 10%, #f4f4f4 360%)",
                        marginTop: "5%",
                        marginBottom: "0%",
                        width: "100%",
                        borderRadius: '0.5rem',
                        '.action-button': { width: 100 }
                    }}
                >
                    <MDButton color='success' className={`action-button`} disabled={checkDisabled()} onClick={() => updateLeadData()}>Assign</MDButton>
                    <MDButton color='warning' className="action-button" onClick={cancel}>Cancel</MDButton>
                </DialogActions>

            </Grid>
        </>
    )
}

export default showSelectedLeads