
export const LOGIN_AUTH_COOKIE = "user_auth";
export const LOGIN_REFRESH_COOKIE = "user_auth_refresh";
export const LOGIN_USERTYPE_COOKIE = "auth_usertype";
export const SUBMISSION_ID = 'submission_id';
export const USER_NAME = 'user_name';
export const RIGHTS = 'rights';
export const NOT_VERIFIED_OR_SUBMITTED = 'NOT_VERIFIED_OR_SUBMITTED';
export const EMP_USER_ID = 'id';
export const LEAD_ASSIGN_MODE = 'lead_assign_mode';
export const UNIVERSAL_SEARCH_COOKIE = 'universal_search';
export const CHATNOTIFICATION = 'chat_notification';
export const TINYMCE_KEY = 'fsy86tg1me6fbv4ii7cvdpsl489lmwl5j3irlgpwfvm2bgfn';
// export const WHATSAPP_REDIRECT_URL = 'http://localhost:3001/';
export const WHATSAPP_REDIRECT_URL = 'https://fdwhatsapp.skillupcode.com/';

import { LOGIN_REQUIRED } from './ErrorCode';
import Cookies from 'js-cookie';

const STATUS_SUCCESS = 'success';
const STATUS_DANGER = 'danger';

/**
 * Handler Response function is responsible
 * show errors if received from the server
 * handle errorCodes if received from the server
 * return boolean status of the request.
 *
 * @param {json} responseJson
 * @param {boolean} showSuccess
 *
 * @returns {boolean} result
 *
 * @author Raneso  <support@raneso.com>
 */
export const responseHandler = async (responseJson, showSuccess) => {

    var result = false;
    var messageType = STATUS_DANGER;

    if (responseJson.success === true) {
        result = true;
        messageType = STATUS_SUCCESS;
    }

    if (!(!showSuccess && messageType === STATUS_SUCCESS)) {
        messageHandler(responseJson.message, messageType);
    }

    if (responseJson.is_login_required) {
        Cookies.remove("authToken")
        alert('User must be logged in');
        window.location.href = "/login";
    }

    await errorHandler(responseJson.error)
    return result;
}

/**
 * Display error messages received from the server
 * By using getMessage function.
 *
 * @param {string|array} message
 *
 * 
 */
const messageHandler = async (message, type) => {

    const newMessage = await getMessage(message);
    //alert(newMessage);
    if (newMessage === 'User must be logged in.') {
        return window.location.href = '/login';
    }
    // Toast.show({ position: 'top', type: type,
    //     buttonText: "Okay", duration: 10000,
    //     text: newMessage,
    // });
}

/**
 * @NOTE we have to logout and than navigate to
 * login screen in case of login required
 *
 * @NOTE we have find a way to handle setState({loading})
 *  Giving warnings right now.
 *
 * @param {int} error
 *
 * 
 */
const errorHandler = (error) => {
    if (error === LOGIN_REQUIRED) {
    }
}

/**
 * Manage error message received from the
 * server and than parse into string than return
 * string.
 *
 * @param {string|array} message
 *
 * @returns {string} result
 *
 * @author Raneso <support@raneso.com>
 */
const getMessage = (message) => {
    var result = '';
    if (typeof message == 'string') {
        result += message;
    } else if (typeof message == 'object') {
        for (var key in message) {
            // skip loop if the property is from prototype
            if (!message.hasOwnProperty(key)) continue;

            var obj = message[key];
            for (var prop in obj) {
                // skip loop if the property is from prototype
                if (!obj.hasOwnProperty(prop)) continue;

                // your code
                result += obj[prop] + '\n'; //prop + " = " +
            }
        }
    }

    return result;
}

